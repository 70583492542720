<template>
    <div>
        <div class="uk-padding-small">
            <vk-breadcrumb class="uk-margin-remove">
                <vk-breadcrumb-item style="cursor: pointer" @click="$router.push({ name: previousRouteInfo.name })">
                    {{ previousRouteInfo.wording }}
                </vk-breadcrumb-item>
                <vk-breadcrumb-item :disabled="true">
                    Intervention {{ $route.params.missionOrderId }}
                </vk-breadcrumb-item>
            </vk-breadcrumb>
        </div>
        <div class="uk-padding-small">
            <div class="d-flex align-items-center" v-if="missionOrderIsUpdating">
                <strong>Chargement en cours de l'intervention {{ $route.params.missionOrderId }}...</strong>
                <div class="spinner-border ms-auto" role="status" aria-hidden="true"></div>
            </div>
            <MissionOrder :mission-order="missionOrder" @refresh="refresh" ref="missionOrder" v-else />
        </div>
    </div>
</template>

<script>
import MissionOrder from '@/components/MissionOrder'
import store from '@/store'
import router from '@/router'
import { mapGetters, mapState } from 'vuex'

const enterUpdateGuard = (to, from, next) => {
    if (
        store.state.login.user.userData.accountType === 'workerAccount' &&
        store.state.login.user.userData.roles.includes('admin')
    ) {
        store.dispatch('users/updateUsersList')
    }

    store.dispatch('missionOrders/setCurrentMissionOrder', to.params.missionOrderId).catch(() => {
        store.dispatch('notifications/addNotification', {
            status: 'warning',
            message: "Échec de chargement de l'intervention",
        })
        router.push({ name: 'mission_orders' })
    })

    next()
}

export default {
    name: 'MissionOrderView',
    components: { MissionOrder },
    methods: {
        /**
         * @param data {{afterSubmit: ('comment' | 'terminated') | undefined}}
         */
        async refresh(data) {
            await this.$store.dispatch('missionOrders/setCurrentMissionOrder', this.missionOrder.id)
            if (this.$refs.missionOrder && this.missionOrder.signature) {
                this.$refs.missionOrder.getSignature(this.missionOrder.signature)
            }
            if (!data.afterSubmit) {
                return
            }
            if (data.afterSubmit === 'comment') {
                this.$refs.missionOrder.terminerChantier()
            }
            if (data.afterSubmit === 'terminated') {
                this.$refs.missionOrder.$refs.event.definitelyEnd()
            }
        },
    },
    computed: {
        ...mapState('missionOrders', {
            missionOrder: 'currentMissionOrder',
            missionOrderIsUpdating: 'currentMissionOrderUpdateIsPending',
        }),
        ...mapGetters('breadcrumbs', ['previousRouteInfo']),
    },
    beforeRouteEnter: enterUpdateGuard,
    beforeRouteUpdate: enterUpdateGuard,
    beforeRouteLeave(to, from, next) {
        store.dispatch('missionOrders/reset')
        next()
    },
}
</script>

<style scoped></style>
